import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import http from 'utils/http';

export const getUsers = createAsyncThunk(
  'user/getUsers',
  async (params, thunkAPI) => {
    try {
      params.append('filter', '{ "get_group": true }');
      const response = await http.get(`/user/`, { params });
      return response;
    } catch (e) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: e?.response?.data?.message,
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  pages: {},
  totalPages: 1,
  isUsersLoading: false,
  currentPage: 1,
  userFilters: {
    username: '',
    teams: [],
    groups: [],
    role: { label: 'All roles', value: 'all' },
    sortBy: {
      created_at: 'desc'
    }
  }
};

const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    resetUsers: (state) => {
      state.pages = {};
      state.totalPages = 1;
      state.currentPage = 1;
    },
    resetFilters: (state) => {
      state.userFilters = {
        username: '',
        teams: [],
        groups: [],
        role: { label: 'All roles', value: 'all' },
        sortBy: {
          created_at: 'desc'
        }
      };
    },
    setUserFilters(state, action) {
      state.userFilters = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.isUsersLoading = action.payload;
    },
    setUserList: (state, action) => {
      const { page, data } = action.payload;
      state.pages[page] = data;
    }
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isUsersLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      const { data, max_pages: maxPages } = action.payload.data;
      state.pages[state.currentPage] = data;
      state.isUsersLoading = false;
      state.totalPages = maxPages;
    },
    [getUsers.rejected]: (state) => {
      state.isUsersLoading = false;
    }
  }
});

export const selectUsersList = (state) => state.userList.pages;
export const selectUsersLoading = (state) => state.userList.isUsersLoading;
export const selectUserFilters = (state) => state.userList.userFilters;
export const selectCurrentPage = (state) => state.userList.currentPage;
export const selectFiltersApplied = (state) => state.userList.filtersApplied;
export const selectTotalPages = (state) => state.userList.totalPages;

const { reducer, actions } = userListSlice;
export const {
  resetUsers,
  resetFilters,
  setUserFilters,
  setCurrentPage,
  setUsersLoading,
  setUserList
} = actions;

export default reducer;
