/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import http from 'utils/http';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';

/**
* Function to filter and generate valid list of tickets and to
* handle list from being poluted by multiple api calls with same params
*/
const validateTicketList = (prevTicketList, newTicketList) => {
  let updatedTicketList = [...prevTicketList];
  const prevTicketsIds = prevTicketList?.map((tkts) => (tkts.ticket_id));
  if (newTicketList?.length && prevTicketsIds?.indexOf(newTicketList[0]?.ticket_id) === -1) {
    updatedTicketList = [...prevTicketList, ...newTicketList].sort((a, b) => (b.ticket_id - a.ticket_id));
  }
  if (newTicketList?.length === 0) {
    updatedTicketList = [];
  }
  return updatedTicketList;
};

/**
 * A redux thunk action creator for fetching tickets
 */
export const getTickets = createAsyncThunk(
  'tickets/getTickets',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/ticket/`, { params });
      return response;
    } catch (e) {
      const errorMessage = e?.response?.Message ? e.response.Message : 'Error in fetching tickets';
      thunkAPI.dispatch(enqueueSnackbar({
        message: errorMessage,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for fetching zendesk views
 */
export const getZendeskViews = createAsyncThunk(
  'tickets/getZendeskViews',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/zendesk/view`, { params });
      return response?.data;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Tickets slice
const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    tickets: [],
    view: 'card',
    pageNumber: 0,
    totalTicketCount: 0,
    totalPages: 0,
    isTicketsLoading: false,
    zendeskViews: [],
    isViewsLoading: false,
    selectedView: {},
    filter: {
      robots: [],
      teams: [],
      search: ''
    }
  },
  reducers: {
    setSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    }
  },
  extraReducers: {
    [getTickets.pending]: (state) => {
      state.isTicketsLoading = true;
    },
    [getTickets.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.tickets = data?.page_number === 1 ? data?.tickets : validateTicketList(state.tickets, data?.tickets);
      state.pageNumber = data?.page_number;
      state.totalTicketCount = data?.total_tickets;
      state.totalPages = data.pages;
      state.isTicketsLoading = false;
    },
    [getTickets.rejected]: (state) => {
      state.isTicketsLoading = false;
    },
    [getZendeskViews.pending]: (state) => {
      state.isViewsLoading = true;
    },
    [getZendeskViews.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.zendeskViews = data;
      state.isViewsLoading = false;
      if (data?.length > 0 && (!state.selectedView?.value)) {
        const [view] = data;
        state.selectedView = view;
      }
    },
    [getZendeskViews.rejected]: (state) => {
      state.isViewsLoading = false;
    }
  }
});

export const { setSelectedView } = ticketsSlice.actions;

export const selectTickets = (state) => (state.tickets.tickets);
export const selectCurrentPage = (state) => (state.tickets.pageNumber);
export const selectTotalTicketCount = (state) => (state.tickets.totalTicketCount);
export const selectTotalPages = (state) => (state.tickets.totalPages);
export const selectTicketsLoading = (state) => (state.tickets.isTicketsLoading);
export const selectZendeskViews = (state) => (state.tickets.zendeskViews);
export const selectSelectedView = (state) => (state.tickets.selectedView);
export const selectViewsLoading = (state) => (state.tickets.isViewsLoading);
export const selectFilter = (state) => (state.tickets.filter);
export const { setView } = ticketsSlice.actions;

const { reducer } = ticketsSlice;
export const { setFilter } = ticketsSlice.actions;
export default reducer;
