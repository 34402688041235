import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import 'typeface-inter';
import { selectTheme, toggleTheme } from 'reducers/themeSlice';
import store from 'app/store';
import App from './App';
import ServiceWorker from './ServiceWorker';
import theme from './theme';

const container = document.getElementById('root');
const root = createRoot(container);
const interRegular = new FontFace('Inter', 'url(/static/media/Inter-Regular.6cc68d6b835a3dd06e7e.woff2)');
const interMedium = new FontFace('Inter', 'url(/static/media/Inter-Medium.ba68c34a21c5751a2838.woff2)');
const interSemibold = new FontFace('Inter', 'url(/static/media/Inter-SemiBold.625474d6ebbfe4e3b0ae.woff2)');
const interBold = new FontFace('Inter', 'url(/static/media/Inter-Bold.a808c21aaa221f6d24f9.woff2)');

interRegular.load();
interMedium.load();
interSemibold.load();
interBold.load();

const ThemeWrapper = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: light)')
      .addEventListener('change', (event) => {
        const systemLightTheme = event.matches;
        dispatch(toggleTheme(systemLightTheme));
      });
  });

  const lightTheme = useSelector(selectTheme);
  return (
    <ThemeProvider theme={lightTheme ? theme.light : theme.dark}>
      {children}
    </ThemeProvider>
  );
};
const isDevelopment = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ServiceWorker>
          <ThemeWrapper>
            <CssBaseline />
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </ThemeWrapper>
        </ServiceWorker>
      </Provider>
    </React.StrictMode>
  );
} else {
  root.render(
    <Provider store={store}>
      <ServiceWorker>
        <ThemeWrapper>
          <CssBaseline />
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </ThemeWrapper>
      </ServiceWorker>
    </Provider>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
