import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import http from 'utils/http';

/**
 * A redux thunk action creator for fetching alerts
 */
export const getAlert = createAsyncThunk(
  'alert/fetchAlert',
  async (params, thunkAPI) => {
    try {
      const { teamId, alertId } = params;
      const response = await http.get(`/team/${teamId}/alerts/${alertId}`);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateAlertStatus = createAsyncThunk(
  'alert/updateAlertStatus',
  async (payload, thunkAPI) => {
    try {
      const { alertId, status, teamId } = payload;
      const response = await http.put(`/team/${teamId}/alerts/${alertId}`, { status });
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alert: {
      alert_id: '',
      robot: '',
      created_at: '',
      issue: '',
      site_name: '',
      pose: '',
      mission_instance_id: '',
      status: '',
      alert_type: '',
      image_url: '',
      map_hash: '',
      timestamp: '',
      robot_id: ''
    },
    isAlertLoading: false,
    isAlertUpdating: false
  },
  reducers: {
    resetAlert: (state) => {
      state.alert = { alert_id: '',
        robot: '',
        created_at: '',
        issue: '',
        site_name: '',
        pose: '',
        mission_instance_id: '',
        status: '',
        alert_type: '',
        image_url: '',
        map_hash: '',
        timestamp: '',
        robot_id: '' };
      state.isAlertLoading = false;
    },
    changeStatus: (state, action) => {
      state.alert.status = action.payload;
    }
  },
  extraReducers: {
    [getAlert.pending]: (state) => {
      state.isAlertLoading = true;
    },
    [getAlert.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isAlertLoading = false;
      state.alert = data;
    },
    [getAlert.rejected]: (state) => {
      state.isAlertLoading = false;
    },
    [updateAlertStatus.pending]: (state) => {
      state.isAlertUpdating = true;
    },
    [updateAlertStatus.fulfilled]: (state) => {
      state.isAlertUpdating = false;
    },
    [updateAlertStatus.rejected]: (state) => {
      state.isAlertUpdating = false;
    }
  }
});

export const selectAlert = (state) => state.alert.alert;
export const selectAlertLoading = (state) => state.alerts.isAlertsLoading;

const { reducer, actions } = alertSlice;
export const { resetAlert, changeStatus } = actions;
export default reducer;
