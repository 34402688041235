import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { selectAccessToken } from 'pages/auth/authSlice';

/**
 * It allows only authenticated user to access the
 * routes wrapped under this component.
 * If user not authenticated, It will redirect to login page.
 */
export default function PrivateRoute() {
  // subscribing to auth accesstoken state
  const accessToken = useSelector(selectAccessToken);

  const redirectToLogin = () => {
    if (window?.location?.pathname.includes('/alert/')) {
      localStorage.setItem('alertNavigationUrl', window?.location?.pathname);
    }
    return <Navigate to="/login" />;
  };

  return accessToken ? <Outlet /> : redirectToLogin();
}
