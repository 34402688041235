import { capitalize } from 'lodash';
import moment from 'moment-timezone';

export const getOrdinalSuffix = (num) => {
  let suffix = 'th';
  if (num % 10 === 1 && num % 100 !== 11) {
    suffix = 'st';
  } else if (num % 10 === 2 && num % 100 !== 12) {
    suffix = 'nd';
  } else if (num % 10 === 3 && num % 100 !== 13) {
    suffix = 'rd';
  }
  return suffix;
};

const PAGE_SIZE = 20;

export const buildAlertParams = (alertFilters, lastEvaluatedKey, newPage, showGroupDetails) => {
  const params = new URLSearchParams({
    limit: PAGE_SIZE,
    sort_by: 'created_at',
    sort_order: 'desc',
    last_evaluated_key: (newPage > 1) ? JSON.stringify(lastEvaluatedKey) : null
  });
  if (alertFilters.search !== '') {
    params.append('search', alertFilters.search);
  }
  if (alertFilters.startDate !== '') {
    params.append('start_date', alertFilters.startDate);
  }
  if (alertFilters.endDate !== '') {
    params.append('end_date', alertFilters.endDate);
  }
  if (alertFilters.teams?.length > 0) {
    const teamIds = alertFilters.teams.map((team) => team.value);
    teamIds.forEach((teamId) => { params.append('team_id', teamId); });
  }
  if (alertFilters.groups?.length > 0) {
    const groupsIds = alertFilters.groups.map((group) => group.value);
    groupsIds.forEach((groupId) => { params.append('group_id', groupId); });
  }
  if (alertFilters.robots?.length > 0) {
    const robotIds = alertFilters.robots.map((robot) => robot.value);
    robotIds.forEach((robotId) => { params.append('robot_ids', robotId); });
  }
  if (alertFilters.categoryFilter === 'va' || alertFilters.categoryFilter === 'fm') {
    params.append('alert_type', alertFilters.categoryFilter);
  }
  if (alertFilters.detectionTypes?.length > 0) {
    const detectionTypeIds = alertFilters.detectionTypes.map((type) => type.value);
    detectionTypeIds.forEach((type) => { params.append('detection_types', type); });
  }
  if (alertFilters.status.length > 0) {
    alertFilters.status.forEach((status) => {
      // this is to handle New status which includes both created and viewed alerts
      const statusList = status.split(',');
      statusList.forEach((s) => {
        params.append('status', s.trim());
      });
    });
  }
  if (showGroupDetails) {
    params.append('get_group', true);
  }
  if (Object.keys(alertFilters.sortBy).length > 0) {
    params.delete('sort_by');
    params.delete('sort_order');
    Object.entries(alertFilters.sortBy).forEach(([colId, sortOrder]) => {
      params.append('sort_by', colId);
      params.append('sort_order', sortOrder);
    });
  }

  return params;
};

export const getLocalDate = (time) => {
  const utcDate = moment.utc(time);
  return utcDate.isValid() ? utcDate.local() : null;
};

export const getTimeAgo = (time) => {
  const createdMoment = getLocalDate(time);
  return createdMoment.isValid() ? createdMoment.fromNow() : 'No time information';
};

export const getDateRange = (days) => {
  const now = new Date();
  // Set the end date to the end of the current day
  const endDate = new Date(now.setHours(23, 59, 59, 999)).toISOString();
  // Set the start date to 'days' ago at the start of the day
  const startDate = new Date(now.setDate(now.getDate() - days)).setHours(0, 0, 0, 0);

  return { startDate: new Date(startDate).toISOString(), endDate };
};

export const generateAlertName = (issue, types) => {
  if (!issue) return issue;
  if (!types?.length) return capitalize(issue); // fallback for old alerts with no detection types

  let detectionText = [...new Set(types)].map((type) => capitalize(type)).join(', ');

  // If only one detection type and ends with "detect/Detect", replace it with "detected"
  if (types.length === 1 && detectionText.match(/detect$/i)) {
    detectionText = detectionText.replace(/detect$/i, 'detected');
  } else {
    detectionText = `${detectionText} detected`;
  }

  // Replace the last comma with ' & ' for multiple types
  if (types.length > 1) {
    const lastCommaIndex = detectionText.lastIndexOf(', ');
    detectionText = `${detectionText.substring(0, lastCommaIndex)
    } & ${
      detectionText.substring(lastCommaIndex + 2)}`;
  }

  // Extract the part of `issue` starting from the first number
  const numberMatch = issue.match(/\d+/);
  let issuePart = '';
  if (numberMatch) {
    const startIndex = numberMatch.index;
    issuePart = issue.substring(startIndex);
  }

  return issuePart ? `${detectionText}: ${issuePart}` : detectionText;
};
