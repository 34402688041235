import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SYSTEM_ADMINISTRATOR, SYSTEM_OPERATOR } from 'constants/roles';
import http from 'utils/http';

export const getUserPermission = createAsyncThunk(
  'permission/getUserPermission',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/user/${params.userId}/permission`);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getRobotSpecificUserPermission = createAsyncThunk(
  'permission/getRobotSpecificUserPermission',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/user/${params.userId}/robot/${params.robotId}/permission`);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
// intial state for the permisiion
const initialState = {
  permissions: [],
  robotPermission: [],
  roles: [],
  isKabamAdmin: false,
  isKabamOperator: false,
  isKabamAdminOrOperator: false,
  permissionsloading: false
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    removePermission: (state) => {
      state.permissions = initialState.permissions;
      state.roles = initialState.roles;
      state.permissionsloading = false;
    }
  },
  extraReducers: {

    [getUserPermission.fulfilled]: (state, action) => {
      state.permissions = action.payload.data?.permissions;
      state.roles = action.payload.data?.roles;
      const isSuperAdmin = action.payload.data?.roles[0]?.role_name === SYSTEM_ADMINISTRATOR;
      const isOperator = action.payload.data?.roles[0]?.role_name === SYSTEM_OPERATOR;
      state.isKabamAdmin = isSuperAdmin;
      state.isKabamOperator = isOperator;
      state.isKabamAdminOrOperator = isSuperAdmin || isOperator;
      state.permissionsloading = false;
    },
    [getUserPermission.pending]: (state) => {
      state.permissionsloading = true;
    },
    [getUserPermission.rejected]: (state) => {
      state.permissionsloading = false;
      state.permissions = null;
    },
    [getRobotSpecificUserPermission.pending]: (state) => {
      state.isRobotPermissionLoading = true;
    },
    [getRobotSpecificUserPermission.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.robotPermission = data?.permissions;
    },
    [getRobotSpecificUserPermission.rejected]: (state) => {
      state.isRobotPermissionLoading = false;
    }
  }
});

export const { removePermission } = permissionSlice.actions;

export const selectUserPermission = (state) => (state?.permission?.permissions);
export const selectUserPermissionLoading = (state) => (state?.permission?.permissionsloading);
export const selectIsKabamAdminOrOperator = (state) => (state?.permission?.isKabamAdminOrOperator);
export const selectUserSpecificRobotPermission = (state) => (state?.permission?.robotPermission);
export const selectIsKabamAdmin = (state) => (state?.permission?.isKabamAdmin);

export const selectUserRoles = (state) => (state?.permission?.roles);

const { reducer } = permissionSlice;
export default reducer;
