import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import http from 'utils/http';

export const getRobots = createAsyncThunk(
  'robotops/getRobots',
  async (params, thunkAPI) => {
    try {
      params.append(
        'fields',
        'robot_id, status, robot_code, nick_name, model_id, activation_date, is_active,'
         + ' last_heartbeat_at, deploy_va_engine, va_engine_status, location, region_id'
      );
      params.append('get_group', true);
      const response = await http.get(`/robot/list`, { params });
      return response;
    } catch (e) {
      if (e.code !== 'ERR_NETWORK') {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: e?.response?.data?.message,
            isClearable: true,
            variant: 'error',
            key: new Date().getTime() + Math.random()
          })
        );
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  pages: {},
  totalPages: 1,
  isRobotsLoading: false,
  currentPage: 1,
  robotFilters: {
    search: '',
    teams: [],
    groups: [],
    models: [],
    sortBy: {
      nick_name: 'asc'
    }
  }
};

const robotListSlice = createSlice({
  name: 'robotList',
  initialState,
  reducers: {
    resetRobots: (state) => {
      state.pages = {};
      state.totalPages = 1;
      state.currentPage = 1;
    },
    resetFilters: (state) => {
      state.robotFilters = {
        search: '',
        teams: [],
        groups: [],
        models: [],
        sortBy: {
          nick_name: 'asc'
        }
      };
    },
    setRobotFilters(state, action) {
      state.robotFilters = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRobotsLoading: (state, action) => {
      state.isRobotsLoading = action.payload;
    },
    setRobotList: (state, action) => {
      const { page, data } = action.payload;
      state.pages[page] = data;
    }
  },
  extraReducers: {
    [getRobots.pending]: (state) => {
      state.isRobotsLoading = true;
    },
    [getRobots.fulfilled]: (state, action) => {
      const { data, max_pages: maxPages } = action.payload.data;
      state.pages[state.currentPage] = data;
      state.isRobotsLoading = false;
      state.totalPages = maxPages;
    },
    [getRobots.rejected]: (state) => {
      state.isRobotsLoading = false;
    }
  }
});

export const selectRobotsList = (state) => state.robotList.pages;
export const selectRobotsLoading = (state) => state.robotList.isRobotsLoading;
export const selectRobotFilters = (state) => state.robotList.robotFilters;
export const selectCurrentPage = (state) => state.robotList.currentPage;
export const selectFiltersApplied = (state) => state.robotList.filtersApplied;
export const selectTotalPages = (state) => state.robotList.totalPages;

const { reducer, actions } = robotListSlice;
export const {
  resetRobots,
  resetFilters,
  setRobotFilters,
  setCurrentPage,
  setRobotsLoading,
  setRobotList
} = actions;

export default reducer;
