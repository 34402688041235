import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import http from 'utils/http';

export const getTeamPermission = createAsyncThunk(
  'team/getTeamPermission',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/team/${params.teamId}/user/${params.userId}/permission`);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getUserTeams = createAsyncThunk(
  'team/getUserTeams',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/team/`);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTeams = createAsyncThunk(
  'team/getTeams',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/team/`, { params });
      return response;
    } catch (e) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: e?.response?.data?.message,
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  userTeams: [],
  isUserTeamsLoading: false,
  teamPermission: [],
  isTeamPermissionLoading: false,
  pages: {},
  totalPages: 1,
  isTeamsLoading: false,
  currentPage: 1,
  teamFilters: {
    search: '',
    sortBy: {
      created_at: 'desc'
    }
  },
  view: 'card'
};

const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    removeUserTeams: (state) => {
      state.userTeams = initialState.userTeams;
    },
    setUserTeams: (state, action) => {
      state.userTeams = action.payload.userTeams;
    },
    resetTeams: (state) => {
      state.pages = {};
      state.totalPages = 1;
      state.currentPage = 1;
    },
    resetFilters: (state) => {
      state.teamFilters = {
        search: '',
        sortBy: {
          created_at: 'desc'
        }
      };
    },
    setTeamFilters(state, action) {
      state.teamFilters = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTeamsLoading: (state, action) => {
      state.isTeamsLoading = action.payload;
    },
    setTeamList: (state, action) => {
      const { page, data } = action.payload;
      state.pages[page] = data;
    },
    setView: (state, action) => {
      state.view = action.payload;
    }
  },
  extraReducers: {
    [getUserTeams.fulfilled]: (state, action) => {
      state.userTeams = action.payload?.data?.data;
      state.isUserTeamsLoading = false;
    },
    [getUserTeams.pending]: (state) => {
      state.isUserTeamsLoading = true;
    },
    [getUserTeams.rejected]: (state) => {
      state.isUserTeamsLoading = false;
    },
    [getTeams.pending]: (state) => {
      state.isTeamsLoading = true;
    },
    [getTeams.fulfilled]: (state, action) => {
      const { data, max_pages: maxPages } = action.payload.data;
      state.pages[state.currentPage] = data;
      state.isTeamsLoading = false;
      state.totalPages = maxPages;
    },
    [getTeams.rejected]: (state) => {
      state.isTeamsLoading = false;
    },
    [getTeamPermission.pending]: (state) => {
      state.isTeamPermissionLoading = true;
    },
    [getTeamPermission.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.teamPermission = data?.permissions;
    },
    [getTeamPermission.rejected]: (state) => {
      state.isTeamPermissionLoading = false;
    }
  }
});

export const selectUserTeams = (state) => state.teams.userTeams;
export const selectUserTeamsLoading = (state) => state.teams.isUserTeamsLoading;
export const selectTeamsList = (state) => state.teams.pages;
export const selectTeamsLoading = (state) => state.teams.isTeamsLoading;
export const selectTeamFilters = (state) => state.teams.teamFilters;
export const selectCurrentPage = (state) => state.teams.currentPage;
export const selectFiltersApplied = (state) => state.teams.filtersApplied;
export const selectTotalPages = (state) => state.teams.totalPages;
export const selectView = (state) => state.teams.view;
export const selectTeamPermission = (state) => state.teams.teamPermission;
export const selectTeamPermissionLoading = (state) => state.teams.isTeamPermissionLoading;

const { reducer, actions } = teamSlice;
export const {
  setUserTeams,
  removeUserTeams,
  resetTeams,
  resetFilters,
  setTeamFilters,
  setCurrentPage,
  setTeamsLoading,
  setTeamList,
  setView
} = actions;

export default reducer;
