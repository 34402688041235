export const SYSTEM_ADMINISTRATOR = 'system - admin';
export const SYSTEM_OPERATOR = 'system - group operator';
export const SYSTEM_PILOT_ONSITE_OPERATOR = 'pilot onsite operator';
export const SYSTEM_DISTRIBUTOR = 'distributor';
export const SYSTEM_PARTNER = 'partner';
export const SYSTEM_USER = 'user';

export const TEAM_ADMIN = 'client - admin';
export const TEAM_OPERATOR = 'client - operator';
export const TEAM_VIEWER = 'client - viewer';

export const GROUP_ADMIN = 'group - admin';
export const GROUP_OPERATOR = 'group - operator';
export const GROUP_VIEWER = 'group - viewer';
