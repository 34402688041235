import { createSlice } from '@reduxjs/toolkit';

// Global app slice
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    deferredPrompt: null,
    showInstallButton: false
  },
  reducers: {
    reset: () => {},
    clearStates: () => {},
    setInstallationParams (state, action) {
      const { deferredPrompt, showInstallButton } = action.payload;
      state.deferredPrompt = deferredPrompt;
      state.showInstallButton = showInstallButton;
    }
  }
});

export const selectDeferredPrompt = (state) => (state.app.deferredPrompt);
export const selectShowInstallButton = (state) => (state.app.showInstallButton);

export const { reset, clearStates, setInstallationParams } = appSlice.actions;

export default appSlice.reducer;
