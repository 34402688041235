import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserPermission, selectUserSpecificRobotPermission } from 'pages/auth/permissionSlice';

/**
 * returns true if user permission present
 * @param permissionName {string} - permission name to be checked
 */
export const userHasPermission = (permissionName, permissions) => {
  if (permissionName === '') return true;
  if (!permissions) return false;
  const permissionFound = permissions.find((permission) => (permission === permissionName));
  return !!permissionFound;
};

/**
 * renders children if user has permission
 * @param permissionName {string} - permission name to be checked
 * @param isAuthorized {function} - callback passing authorization value
 * @param children {node} - any children node
 */
function Permission({ permissionName, isAuthorized, children, isGlobal }) {
  const permissions = useSelector(selectUserPermission);
  const robotSpecificPermission = useSelector(selectUserSpecificRobotPermission);

  const authorized = userHasPermission(permissionName, isGlobal ? permissions : robotSpecificPermission);
  isAuthorized(authorized);
  if (authorized) return children;
  return null;
}

Permission.propTypes = {
  permissionName: PropTypes.string.isRequired,
  isAuthorized: PropTypes.func,
  children: PropTypes.node,
  isGlobal: PropTypes.bool
};

Permission.defaultProps = {
  isAuthorized: () => null,
  isGlobal: true
};

export default Permission;
