// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import snackbar from 'snackbar/snackbarSlice';
import fullscreen from 'fullscreen/fullscreenSlice';
import tickets from 'pages/tickets/ticketsSlice';
import ticket from 'pages/ticket/ticketSlice';
import robotops from 'pages/robotops/robotopsSlice';
import dashboard from 'pages/dashboard/dashboardSlice';
import robot from 'pages/robotpage/robotSlice';
import auth from 'pages/auth/authSlice';
import offline from 'reducers/offlineSlice';
import push from 'reducers/pushSlice';
import theme from 'reducers/themeSlice';
import loading from 'components/Loaders/loadingSlice';
import alerts from 'pages/alerts/alertsSlice';
import alert from 'pages/alert/alertSlice';
import tour from 'product-tour/tourSlice';
import permission from 'pages/auth/permissionSlice';
import teams from 'pages/management/components/teams/teamSlice';
import smartReport from 'pages/robotpage/components/overview/smartReportSlice';
import robotList from 'pages/management/components/robot/robotListSlice';
import userList from 'pages/management/components/user/userListSlice';
import app from 'reducers/appSlice';

const appReducer = combineReducers({ auth,
  snackbar,
  fullscreen,
  offline,
  dashboard,
  robotops,
  robot,
  push,
  tickets,
  ticket,
  alert,
  alerts,
  theme,
  tour,
  loading,
  permission,
  teams,
  smartReport,
  robotList,
  userList,
  app });

// returns single reducer by combining all reducers
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial except auth, theme
  if (action.type === 'app/reset') {
    state = { auth: state?.auth, theme: state?.theme };
  }
  if (action.type === 'app/clearStates') { state = { theme: state?.theme }; }

  return appReducer(state, action);
};
export default rootReducer;
