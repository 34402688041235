import { createAsyncThunk } from '@reduxjs/toolkit';
import http from 'utils/http';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import store from 'app/store';

// reset password for a logged out user
export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (params, thunkAPI) => {
    try {
      const response = await http.put(`/user/password/reset`, params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Your password has been reset successfully',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// change password for a logged in user
export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (params, thunkAPI) => {
    try {
      const response = await http.put(`/user/password`, params);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getNotificationStatus = createAsyncThunk(
  'user/getNotificationStatus',
  async (params, thunkAPI) => {
    try {
      const response = await http.get('user/notification', { params });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const toggleNotificationStatus = createAsyncThunk(
  'user/toggleNotificationStatus',
  async (params, thunkAPI) => {
    try {
      const response = await http.put('user/notification', params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: `${params.notification_status ? 'Enabled' : 'Disabled'} email notifcations`,
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Failed to toggle email notifcations',
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getQrCode = createAsyncThunk(
  'user/getQrCode',
  async (thunkAPI) => {
    try {
      const response = await http.get('user/mfa/token', {
        responseType: 'blob'
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const verifyPassword = async (params) => {
  try {
    const res = await http.post(`user/login`, params);
    return res;
  } catch (e) {
    return e;
  }
};

export const activateTFA = createAsyncThunk(
  'user/activateTFA',
  async (params, thunkAPI) => {
    try {
      const response = await http.post('user/mfa/activate', params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Two-Factor successfully enabled',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (e) {
      return e.response;
    }
  }
);

export const deactivateTFA = createAsyncThunk(
  'user/deactivateTFA',
  async (params, thunkAPI) => {
    try {
      const response = await http.post('user/mfa/deactivate');
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Two-Factor successfully disabled',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resendVerificationEmail = async () => {
  try {
    const response = await http.post(`user/email/resend`);
    store.dispatch(enqueueSnackbar({
      message: 'Verification has been sent to your email',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

export const registerDevice = async (params) => {
  try {
    const response = await http.post(`push_notification/`, params);
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
// activates push notification for he given device_id
export const activateDevice = async (params) => {
  try {
    const response = await http.post(`push_notification/${params.device_id}/activate`);
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
// de-activates push notification for he given device_id
export const deactivateDevice = async (params) => {
  try {
    const response = await http.post(`push_notification/${params.device_id}/deactivate`);
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
// updates settings push notification for he given device_id
export const updateDeviceSettings = async (params) => {
  try {
    const response = await http.put(`push_notification/${params.device_id}/settings`,
      {
        'va.positive_detection': false,
        'fm.remote_tagging': false,
        'robot.mislocalisation': false,
        'robot.idling': false,
        'robot.bumper_lockout': false,
        'robot.safety_lockout': false,
        'robot.robot_error': false,
        'robot.health_error': false,
        'mission.complete': false,
        'status.battery_critical': false,
        'status.charging_complete': false,
        'e_stop.activation': false,
        'call.incoming_call': false,
        'escalation_delegation.alert_escalation': false,
        'escalation_delegation.task_delegation': false,
        ...params.settings
      });
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
// return current push notification settings for the given device_id
export const getDeviceSettings = async (params) => {
  try {
    const response = await http.get(`push_notification/${params.device_id}/settings`);
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
// return current push notification settings for the given deviceToken
export const testDevice = async (params) => {
  try {
    const response = await http.post(`push_notification/test`, { device_token: params.deviceToken });
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

export const uploadTemoraryLogo = async (params, teamId) => {
  try {
    const response = await http.post(`team/${teamId}/logo`, params);
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

export const updateTeamLogo = async (params, teamId) => {
  try {
    const response = await http.put(`team/${teamId}/logo`, params);
    return response;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
