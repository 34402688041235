import http from 'utils/http';
import store from 'app/store';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';

/**
 * API call to get alerts
 * @param params {Object} - query parameters
 */
export const fetchAlerts = async (params) => {
  try {
    const res = await http.get(`/alerts`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

export const fetchFMCategories = async () => {
  try {
    const res = await http.get(`/image-category/`);
    return { data: res.data, status: 200 };
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

export const fetchVACategories = async () => {
  try {
    const res = await http.get(`/va-category/`);
    return { data: res.data, status: 200 };
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};
